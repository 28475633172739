import React from 'react';

import { BorderRadius } from '../../utils/variables';

import './inner.css';

export default function LightboxInner({ children }) {
  return (
    <div className="lightboxInner" style={innerStyles}>
      {children}
    </div>
  );
}

const innerStyles = {
  backgroundColor: `#fff`,
  borderRadius: BorderRadius,
  width: `680px`,
  maxWidth: `100%`,
  display: `block`,
  margin: `0 auto`,
};
